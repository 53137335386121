export const tableColumns = [
	{
		model: 'ProductId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ProductSet',
		i18n: 'series',
		sortable: true,
		filter: 'dropdown',
	},
	{
		model: 'ProductName',
		i18n: 'name2999',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'ProductPrice',
		i18n: 'listprice',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ProductPriceSale',
		i18n: 'discountedprice',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ProductPriceAdmin',
		i18n: 'priceinlandlord',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ProductMonths',
		i18n: 'months',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ProductNote',
		i18n: 'notefornewlyreg',
		sortable: true,
		filter: 'text',
	},
	{
		colType: 'country',
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
	},
	{
		colType: 'boolean',
		model: 'ProductIsEnabled',
		i18n: 'active',
		sortable: true,
	},
	{
		colType: 'boolean',
		model: 'ProductIsMain',
		i18n: 'main',
		sortable: true,
	},
];
