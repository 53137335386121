<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="packages2997"
		tooltipMsg="pimanualforpack"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OreservationDatetime', ascending: 0 }"
		modelId="ProductId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #DateStart="list">{{ list.row.DateStart | date }}</template>

		<template #DateEnd="list">{{ list.row.DateEnd | date }}</template>

		<template #ProductSet="list">{{ getProductSetById(list.row.ProductSet) }}</template>

		<template #ProductPrice="list">
			{{ list.row.ProductPrice | currency(getCurrencyById(list.row.CountryId)) }}
		</template>

		<template #ProductPriceSale="list">
			{{ list.row.ProductPriceSale | currency(getCurrencyById(list.row.CountryId)) }}
		</template>

		<template #ProductPriceAdmin="list">
			{{ list.row.ProductPriceAdmin | currency(getCurrencyById(list.row.CountryId)) }}
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import serviceEnums from '@/services/service/enums.service';

import { tableColumns } from './product.table-data';

export default {
	name: 'ProductTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ProductListItem',
			productSetDict: serviceEnums.getEnumDict('productSet'),
			currencyDict: serviceEnums.getEnumDict('currency'),
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(ProductId = 0) {
			this.$router.push({
				name: links.productDetailName,
				params: { ProductId },
			});
		},

		async prepareColumns(columns) {
			let cols = prepareProductSet(columns);

			return cols;

			function prepareProductSet(columns) {
				const values = serviceEnums.getEnumForDropdown('productSet', true);

				return setDropdownOptions(columns, 'ProductSet', values);
			}
		},

		getProductSetById(productSetId) {
			return this.productSetDict[productSetId].Text;
		},

		getCurrencyById(currencyId) {
			return this.currencyDict[currencyId].Text;
		},
	},
};
</script>