var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "packages2997",
          tooltipMsg: "pimanualforpack",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "OreservationDatetime", ascending: 0 },
          modelId: "ProductId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "DateStart",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm._f("date")(list.row.DateStart)))]
              },
            },
            {
              key: "DateEnd",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm._f("date")(list.row.DateEnd)))]
              },
            },
            {
              key: "ProductSet",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm.getProductSetById(list.row.ProductSet))),
                ]
              },
            },
            {
              key: "ProductPrice",
              fn: function (list) {
                return [
                  _vm._v(
                    "\n\t\t" +
                      _vm._s(
                        _vm._f("currency")(
                          list.row.ProductPrice,
                          _vm.getCurrencyById(list.row.CountryId)
                        )
                      ) +
                      "\n\t"
                  ),
                ]
              },
            },
            {
              key: "ProductPriceSale",
              fn: function (list) {
                return [
                  _vm._v(
                    "\n\t\t" +
                      _vm._s(
                        _vm._f("currency")(
                          list.row.ProductPriceSale,
                          _vm.getCurrencyById(list.row.CountryId)
                        )
                      ) +
                      "\n\t"
                  ),
                ]
              },
            },
            {
              key: "ProductPriceAdmin",
              fn: function (list) {
                return [
                  _vm._v(
                    "\n\t\t" +
                      _vm._s(
                        _vm._f("currency")(
                          list.row.ProductPriceAdmin,
                          _vm.getCurrencyById(list.row.CountryId)
                        )
                      ) +
                      "\n\t"
                  ),
                ]
              },
            },
          ],
          null,
          false,
          505028272
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }